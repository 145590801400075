<template>
    <div class="app flex-row align-items-center">

        <b-row style="max-width: 100%;padding-left:0px !important;padding-right: 0px; !important;margin: 0px;">


            <div v-if="show == true" id="principal" class="col-md-12">
                <mdb-row class="mt-5 align-items-center justify-content-start">
                    <div class="col-md-6">
                        <h4 class="demo-title"><strong><span @click="VolverPanel" style="cursor: pointer">Panel Administrativo</span> > Delegaciones</strong></h4>
                    </div>
                    <div class="col-md-2">

                    </div>
                    <div class="col-md-2">

                    </div>
                    <div class="col-md-2">
                        <mdb-btn style="float:right" color="primary"  @click="NuevaDelegacion" size="lg">Crear Delegación</mdb-btn>
                    </div>
                </mdb-row>

                <hr />

                <div style="padding: 10px"></div>
                <b-table striped hover selectable @row-clicked="IrDetalle" :fields="fields" :items="datos">

                    <template v-slot:cell(Imagen)="data">
                        <img v-bind:src="'https://api.agustinos.tv/api/imagenes/delegacion/'+data.item.id +'/' + token+'?w=150'">
                    </template>


                </b-table>



                <hr />
                <div style="padding: 10px"></div>
                <b-row>
                    <div class="col-md-2">
                        <mdb-btn color="primary"  @click="IrPaginaAnterior" size="lg">Anterior</mdb-btn>
                    </div>
                    <div class="col-md-8">

                    </div>
                    <div class="col-md-2">
                        <mdb-btn style="float:right" @click="IrPaginaSiguiente" color="primary" size="lg">Siguiente</mdb-btn>
                    </div>
                </b-row>
                <div style="padding: 10px"></div>







            </div>

        </b-row>

        <vue-cookie-accept-decline
                :ref="'myPanel1'"
                :elementId="'myPanel1'"
                :debug="false"
                :position="'bottom-left'"
                :type="'floating'"
                :disableDecline="true"
                :transitionName="'slideFromBottom'"
                :showPostponeButton="false"
                @status="cookieStatus"
                @clicked-accept="cookieClickedAccept"
                @clicked-decline="cookieClickedDecline">

            <!-- Optional -->
            <div slot="postponeContent">
                &times;
            </div>

            <!-- Optional -->
            <div slot="message">
                Agustinos.tv cumple con la normativa de cookies y privacidad.<a href="https://agustinostv-front.archive.tornadocore.es/proteccion_de_datos.pdf" target="_blank">Leer más </a>
            </div>


            <!-- Optional -->
            <div slot="acceptContent">
                ACEPTAR
            </div>
        </vue-cookie-accept-decline>
    </div>



</template>

<script>

    import { mdbBreadcrumb,
        mdbBreadcrumbItem,
        mdbIcon,
        mdbRow,
        mdbContainer,
        mdbCard,
        mdbCardImage,
        mdbCardBody,
        mdbCardTitle,
        mdbCardText,
        mdbCol,
        mdbView,
        mdbBtn,
        mdbMask,
        mdbCardGroup,  } from 'mdbvue';
    import ApiService from '@/services/api';

    export default {
        name: 'UsuariosAdmin',
        components: {
            mdbBreadcrumb,
            mdbBreadcrumbItem,
            mdbContainer,
            mdbRow,
            mdbIcon,
            mdbCard,
            mdbCardImage,
            mdbCardBody,
            mdbCardTitle,
            mdbCardText,
            mdbView,
            mdbMask,
            mdbCol,
            mdbBtn,
            mdbCardGroup,
        },
        data() {
            return {
                show: false,
                token : localStorage.getItem('token'),
                seleccionados: [],
                fields: [
                    {key: 'id', label: 'Id', sortable: true },
                    'Imagen',
                    {key: 'nombre', label: 'Nombre', sortable: true},
                    {key: 'web', label: 'Web', sortable: true},
                    {key: 'created_at', label: 'Fecha creación', sortable: true, formatter (value) {return  moment(String(value)).format('DD/MM/YYYY HH:mm:ss')} },
                ],
                datos : [],
                esta_en_vertical: false,
                pagina_actual : 1,
                is_mobile:'',
                selected_delegacion : null,
                selected_tipo : null,
                selected_orden: 'DESC',
                selected_nombre : '',
                delegaciones:null,
            }
        },
        methods: {
            IrDetalle(item,index,event)
            {

                this.$router.push({ path: '/admin/delegaciones/' + item.id})

            },
            IrPaginaSiguiente()
            {
                this.pagina_actual = this.pagina_actual + 1;
                this.ReloadData();
                var element = document.getElementById("app");
                var top = element.offsetTop;
                window.scrollTo(0, top);
            },
            IrPaginaAnterior()
            {
                this.pagina_actual = this.pagina_actual - 1;
                this.ReloadData();
                var element = document.getElementById("app");
                var top = element.offsetTop;
                window.scrollTo(0, top);
            },
            VolverPanel()
            {
                this.$router.push({path: '/admin'});
            },
            NuevaDelegacion()
            {
                this.$router.push({path: '/admin/nueva-delegacion'});
            },

            FechaSQLtoFechaES(entrada)
            {
                return  moment(String(entrada)).format('DD/MM/YYYY hh:mm:ss');
            },
            //Ordenar las novedades de más reciete a anterior (Son distintos tipos de objetos)
            compare(a, b) {

                var dateA = moment(a.created_at, "YYYY-MM-DD"); // replace format by your one
                var dateB = moment(b.created_at, "YYYY-MM-DD");

                if(dateA.diff(dateB) > 0) {
                    return -1;
                }
                if(dateB.diff(dateA) > 0) {
                    return 1;
                }
                return 0;

            },
            ReloadData()
            {
                ApiService.getDelegaciones(this.pagina_actual,'DESC',25).then( response => {
                    this.datos = response.data.data;
                    console.log('delegaciones');
                    console.log(this.datos);

                });

            },

        },
        mounted(){
            ApiService.getMe().then(r => {
                if(r.data.role != 'ROOT' && r.data.role != 'ADMIN' && r.data.role != 'SUPERVISOR')
                {
                    this.$swal.fire({
                        title: 'Acceso no autorizado',
                        text: 'No lo intentes, de aquí en adelante no tienes acceso',
                        type: 'error',
                        showCancelButton: false,
                    });
                    this.$router.push({path: '/'});
                }
                else {
                    this.show = true;
                }

            });

        },
        created(){


            ApiService.isMobile().then(response => {
                console.log('isMobile');
                console.log(response);
                this.is_mobile = response.data.is_mobile;
                if(localStorage.getItem('is_mobile'))
                {

                    this.is_mobile = localStorage.getItem('is_mobile');
                    console.log('leyendo is_mobile : ' + this.is_mobile );
                }

                else {
                    console.log('asignando is_mobile = ' + this.is_mobile);
                    localStorage.setItem('is_mobile', this.is_mobile);
                    if(this.is_mobile == true) {
                        localStorage.setItem('res_video', '360p');
                    }
                    else {
                        localStorage.setItem('res_video', '720p');
                    }
                    this.$forceUpdate();
                }
            });

            this.ReloadData();
        },
        computed: {

        }

    }


</script>

<style>
    html {
        scroll-behavior: smooth;
    }
</style>
